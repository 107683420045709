import React from "react";
import { useTranslation } from "react-i18next";
import "../i18next";

function Banner() {
  const { t } = useTranslation();
  return (
    <section className="section-banner parallax1">
      <div className="overlay-banner"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="tf-banner center wow fadeInUp">
              {/* <img
                src="./assets/images/background/SpecialtyCoffees2.png"
                alt=""
                className=""
              /> */}
              <div className="title-banner">{t("HomeBanner.1")}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
