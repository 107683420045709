import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import "../i18next";
import HeaderMenu from "../components/HeaderMenu";
import whatsnewsData from "../data/whatsnews.json";

function WhatsNews() {
  const { t } = useTranslation();
  const [dataNews, setDataNews] = useState([]);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    console.log("=>lang: ", lang);
    const items = whatsnewsData.data.filter((item) => item.lang === lang);
    console.log("=>items: ", items);

    if (items.length > 0) {
      setDataNews(items[0].data);
    } else {
    }
  }, []);
  return (
    <div className="counter-scroll">
      <header id="headerTeamWhatsNews">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </header>

      <section className="main-blog">
        <div className="container">
          <Link to="/whatsnews">{t("HomeWhats.1")}</Link>

          <br />
          <br />
          <div className="row">
            {dataNews.map((item) => {
              return (
                <div key={`${item.id}`} className="col-md-4 col-sm-4">
                  <div className="our-news-box wow fadeInUp">
                    <div className="img-news-box">
                      <img
                        src="images/shortcode/blog-1.jpg"
                        alt=""
                        className=""
                      />
                      <div className="overlay-news"></div>
                    </div>
                    <div className="list-news">
                      <p className="wrap-title-news"> {item.From} </p>
                      <a href="blog-detail.html" className="">
                        <h3 className=""> {item.Titulo} </h3>
                      </a>
                      <p className="subtext-blog">{parse(item.TituloDesc)}</p>
                      <span className="subtext-blog-date">
                        {item.created_at}
                      </span>
                      <Link
                        to={`/whatsnewsdetails/${item.id}`}
                        className="icon-right-box"
                      >
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
export default WhatsNews;
