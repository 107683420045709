import React, { useState, useEffect } from "react";
import "../i18next";
import { useTranslation } from "react-i18next";

import united_states from "./images/lang/united-states.svg";
import mexico from "./images/lang/mexico.svg";

const language_default =
  localStorage.getItem("language") !== null
    ? localStorage.getItem("language")
    : "English";

function ChangeLanguage() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(language_default);

  const handleClick = (language) => {
    i18n.changeLanguage(language);
    let lang = language === "en" ? "English" : "Spanish";
    setLang(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    let lang_default = lang === "English" ? "en" : "es";
    handleClick(lang_default);
  }, []);

  useEffect(() => {
    console.log("language_default", language_default);
  }, [language_default]);

  return (
    <>
      <div className="language">
        <ul>
          <li className="current">
            <a>
              <img
                src={lang === "English" ? united_states : mexico}
                alt={lang}
              />{" "}
              {lang}
            </a>
            <ul>
              {lang !== "English" && (
                <li>
                  <a
                    onClick={() => {
                      handleClick("en");
                    }}
                  >
                    <img src={united_states} alt="English" /> English
                  </a>
                </li>
              )}
              {lang !== "Spanish" && (
                <li>
                  <a
                    onClick={() => {
                      handleClick("es");
                    }}
                  >
                    <img src={mexico} alt="Spanish" /> Spanish
                  </a>
                </li>
              )}
              {/* <li><a onClick={() => { handleClick("chi")}} ><img src={china} alt="Chinese"/> Chinese</a></li> */}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}
export default ChangeLanguage;
