import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";

function Testimonials() {
  const { t } = useTranslation();
  return (
    <div>
      <section className="section-testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="heading-testimonials wow fadeInUp">
                <p className="subtitle-counter subtitle-counter">
                  {t("HomeTestimonials.1")}
                </p>
                <h3 className="">{t("HomeTestimonials.2")}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tf-testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div
                className="themesflat-slider"
                data-margin="30"
                data-nav="false"
                data-dots="false"
                data-item="2"
                data-item2="1"
                data-item3="1"
              >
                <div className="tf-testi center">
                  <div className="testimonials1-carousel owl-carousel owl-theme">
                    <div className="testi-box wow fadeInUp">
                      <img
                        src="./assets/images/testimoniales/Scott _200.png"
                        alt=""
                        className="img-testimonial"
                      />
                      <h3 className="">{t("HomeTestimonials.3")}</h3>
                      <ul className="design">
                        <li className="name-design">
                          {t("HomeTestimonials.4")}
                        </li>
                        <li className="work-design">
                          {t("HomeTestimonials.5")}
                        </li>
                      </ul>
                    </div>
                    <div className="testi-box wow fadeInUp">
                      <img
                        src="./assets/images/testimoniales/David_200.png"
                        alt=""
                        className="img-testimonial"
                      />

                      <h3 className=""> {parse(t("HomeTestimonials.6"))}</h3>
                      <ul className="design">
                        <li className="name-design">
                          {t("HomeTestimonials.7")}
                        </li>
                        <li className="work-design">
                          {parse(t("HomeTestimonials.8"))}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="button-service center wow fadeInUp">
            <a className="gain-button" href="/testimonials">
              {parse(t("HomeTestimonials.9"))}
            </a>
          </div>
          <br />
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
