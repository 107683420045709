import React from "react";
import { useTranslation } from "react-i18next";
import "../i18next";
function Team() {
  const { t } = useTranslation();
  return (
    <section className="section-team">
      <div className="container">
        <div className="heading-team center">
          <h1 className="title-team-home wow fadeInUp">{t("HomeTeam.1")}</h1>
          <br />
        </div>
        <div
          className="themesflat-slider"
          data-margin="30"
          data-nav="false"
          data-dots="false"
          data-item="4"
          data-item2="2"
          data-item3="1"
        >
          <div className="team1-carousel owl-carousel owl-theme">
            <div className="team-box wow fadeInUp">
              <div className="hover-team">
                <img
                  src="./assets/images/products/ARABICA_COLOMBIA_CR95_SARCHIMOR.png"
                  alt=""
                  className="img-team-box"
                />
              </div>
              <div className="list-team-box">
                <h3 className="title-team-box">{t("HomeTeam.2")}</h3>

                <span className="icon-team">
                  <a
                    href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://web.facebook.com/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://wa.me/message/DFGA4EJTLAK5E1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
            <div className="team-box wow fadeInUp">
              <div className="hover-team">
                <img
                  src="./assets/images/products/ARABICA_PACAMARA.png"
                  alt=""
                  className="img-team-box"
                />
              </div>
              <div className="list-team-box">
                <h3 className="title-team-box">{t("HomeTeam.3")}</h3>

                <span className="icon-team">
                  <a
                    href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://web.facebook.com/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://wa.me/message/DFGA4EJTLAK5E1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
            <div className="team-box wow fadeInUp">
              <div className="hover-team">
                <img
                  src="./assets/images/products/ARABICA_TYPICA.png"
                  alt=""
                  className="img-team-box"
                />
              </div>
              <div className="list-team-box">
                <h3 className="title-team-box">{t("HomeTeam.4")}</h3>

                <span className="icon-team">
                  <a
                    href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://web.facebook.com/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://wa.me/message/DFGA4EJTLAK5E1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
            <div className="team-box wow fadeInUp">
              <div className="hover-team">
                <img
                  src="./assets/images/products/ARABICA_MASRSELLESA.png"
                  alt=""
                  className="img-team-box"
                />
              </div>
              <div className="list-team-box">
                <h3 className="title-team-box">{t("HomeTeam.5")}</h3>

                <span className="icon-team">
                  <a
                    href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://web.facebook.com/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://wa.me/message/DFGA4EJTLAK5E1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
