import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import "../i18next";
import HeaderMenu from "../components/HeaderMenu";
import whatsnewsData from "../data/whatsnews.json";
import parse from "html-react-parser";

function WhatsNews_details() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState({
    id: null,
    From: "",
    Titulo: "",
    TituloDesc: "",
    Texto: "",
    FotoTipo: "",
    Foto: "",
    created_at: "",
  });

  useEffect(() => {
    const lang = localStorage.getItem("language");
    console.log("=>lang: ", lang);
    const items = whatsnewsData.data.filter((item) => item.lang === lang);
    console.log("=>items: ", items);
    console.log("=>params: ", id);
    if (items.length > 0) {
      if (id) {
        const itemData = items[0].data.filter(
          (i) => parseInt(i.id) === parseInt(id)
        );
        if (itemData.length) setItem(itemData[0]);
        setLoading(false);
      }
    } else {
      items = whatsnewsData.data.filter((item) => item.lang === "English");
      if (id) {
        const itemData = items[0].data.filter(
          (i) => parseInt(i.id) === parseInt(id)
        );
        if (itemData.length) setItem(itemData[0]);
        setLoading(false);
      }
    }
  }, [id]);

  if (isLoading) {
    return (
      <div className="preloader">
        <div className="clear-loading loading-effect-2">
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <div className="counter-scroll">
      <header id="headerTeamWhatsNews">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </header>

      <section className="main-content">
        <div className="container">
          <Link to="/whatsnews">{t("HomeTestimonialsDetails.1")} </Link>
          {"->"} {item.Titulo}
          <br />
          <br />
          {item.id !== null && (
            <div className="row">
              <div className="col-md-10 col-sm-10">
                <div className="post-wrap">
                  <article className="main-post">
                    <div className="img-box-blog">
                      <p className="wrap-title-blog">{item.From}</p>
                      <br />
                      <br />
                    </div>
                    <div className="heading-post">
                      <h2 className="post-title">{item.Titulo}</h2>
                      {item.FotoTipo === "img" && (
                        <img
                          src={`https://shop.rwtc.mx/assets/images/whatsnews/${item.Foto}`}
                          alt=" "
                          className=""
                        />
                      )}
                      {item.FotoTipo !== "img" && <br />}
                      <br />
                      <br />
                      {parse(item.Texto)}
                    </div>
                    {item.FotoTipo === "youtube" && (
                      <div className="img-video">
                        <div className="video-responsive">
                          <ReactPlayer url={item.Foto} />
                        </div>
                      </div>
                    )}
                    <br />
                  </article>
                </div>
              </div>

              <div className="col-md-2 col-sm-2"></div>
            </div>
          )}
          {item.id === null && (
            <div className="row">
              <div className="col-md-10 col-sm-10">
                <div className="our-news-box wow fadeInUp">
                  <div className="img-news-box">
                    {t("HomeTestimonialsDetails.2")}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
export default WhatsNews_details;
