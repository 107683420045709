import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";
import "react-responsive-modal/styles.css";

import HeaderMenu from "../components/HeaderMenu";

function AboutUS() {
  const { t } = useTranslation();
  return (
    <div className="counter-scroll">
      <header id="headerAboutUS">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
            <div className="wrap-banner">
              <div className="heading-title-page">
                {parse(t("HomeAboutUS.1"))}
              </div>
              <span className="heading-title-content">
                <span className="style-5"> {parse(t("HomeAboutUS.2"))} </span>
              </span>
            </div>
          </div>
        </div>
      </header>

      <section className="section-about tf-bottom ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tf-about center wow fadeInUp">
                <h1 className="title-about">{parse(t("HomeAboutUS.3"))}</h1>

                <p className="inner-title-about">
                  {" "}
                  {parse(t("HomeAboutUS.4"))}
                </p>
                <p className="text-about">{parse(t("HomeAboutUS.5"))}</p>
              </div>
            </div>
            <img
              src="./assets/images/about/WorldMapwithNavigation.png"
              alt="image"
              className=""
            />
          </div>
        </div>
      </section>

      <section className="tf-bottom-about ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <span className="about-subtitle">
                {parse(t("HomeAboutUS.6"))}
              </span>
              <div className="tf-text-service wow fadeInUp">
                <ul className="ul-about">{parse(t("HomeAboutUS.7"))}</ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <span className="about-subtitle">
                {parse(t("HomeAboutUS.8"))}
              </span>
              <div className="tf-text-service wow fadeInUp">
                <ul className="ul-about">{parse(t("HomeAboutUS.9"))}</ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-bottom-about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="title-footer-bottom-about">
                <i> {parse(t("HomeAboutUS.10"))} </i>
              </div>
            </div>
            <div className="col-md-6">
              <div className="button-footer wow fadeInUp">
                <a
                  className="gain-button"
                  href="https://wa.me/message/DFGA4EJTLAK5E1"
                  target="_blank"
                >
                  {parse(t("HomeAboutUS.11"))}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutUS;
