import React, { useState, useContext, useEffect } from "react";
import { Button } from "reactstrap";
import HeaderMenu from "../../components/HeaderMenu";
import CryptoJS from "crypto-js";

import { AuthContext } from "../AuthProvider";

function Login() {
  const [state, dispatch] = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [dataLogin, setDataLogin] = useState({ Username: "", Passwd: "" });

  useEffect(() => {}, []);
  useEffect(() => {
    console.log("=>state: ", state);
  }, [state]);

  const onSubmit = () => {
    console.log("=>Login: ", dataLogin);

    if (isLoading) return true;
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: dataLogin.Username,
        password: dataLogin.Passwd,
      }),
    };
    fetch("https://api.rwtc.mx/api/login", requestOptions)
      .then((response) => response.json())
      .then((dataRS) => {
        console.log("=>RSRS:", dataRS);
        if (dataRS.status === "error") {
          console.log("=>Login Error: ", dataRS.message);
        }

        if (dataRS.status === "success") {
          const UserData = CryptoJS.AES.encrypt(
            JSON.stringify(dataRS.data),
            "RwTCmx2022$$$zI1NiJ9.eyJpc3MiOiJ"
          );
          dispatch({ type: "SET_LOGIN", payload: UserData });
          console.log("=>bcrip UserData: ", UserData);
          console.log("=>Login Success: ", dataRS.data);
        }
      })
      .catch((error) => {
        console.error("=>Error:", error);
      })
      .finally(() => {
        console.log("=>finally");
        setLoading(false);
      });
  };

  const onCheck = () => {
    const dataCript = localStorage.getItem("tl_usr");
    console.log("=>getLogin Check: ", dataCript);

    const UserData = CryptoJS.AES.decrypt(
      dataCript,
      "RwTCmx2022$$$zI1NiJ9.eyJpc3MiOiJ"
    ).toString(CryptoJS.enc.Utf8);
    //const UserData = JSON.parse(localStorage.getItem("tl_usr"));
    console.log("=>UserData: ", JSON.parse(UserData));
  };

  return (
    <>
      <div>
        <header id="headerLogin">
          <div className="container-fluid">
            <div className="row">
              <div className="btn-menu">
                <span></span>
              </div>
              <HeaderMenu />
              <div className="wrap-banner">
                <div className="heading-title-login-page">
                  Acceso a Usuarios
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="main-content">
          <div className="container">
            <div className="row center">
              <div className="col-md-4 col-sm-4 center-block" />
              <div className="col-md-4 col-sm-4 center-block">
                <form onSubmit={onSubmit}>
                  <h3>Sign In</h3>

                  <div className="form-group">
                    <label>Username</label>
                    <input
                      className="form-control"
                      placeholder="Usuario"
                      onChange={(e) =>
                        setDataLogin({ ...dataLogin, Username: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Contraseña"
                      onChange={(e) =>
                        setDataLogin({ ...dataLogin, Passwd: e.target.value })
                      }
                    />
                  </div>

                  <Button
                    block
                    className="btn-round mb-3"
                    color="primary"
                    onClick={onSubmit}
                  >
                    Iniciar Sesión
                  </Button>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="primary"
                    onClick={onCheck}
                  >
                    check
                  </Button>
                </form>
                <br />
                <br />
              </div>
              <div className="col-md-4 col-sm-4 center-block" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Login;
