import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import "../i18next";

import HeaderMenu from "../components/HeaderMenu";
import SliderWrapper from "../components/SliderWrapper";
import Services from "../components/Services";
import RWTCModel from "../components/RWTCModel";
import Banner from "../components/Banner";
import Team from "../components/Team";
import SpecialProcess from "../components/SpecialProcess";
import Testimonials from "../components/Testimonials";

function Index() {
  const { t } = useTranslation();

  return (
    <>
      <div className="counter-scroll">
        <header id="">
          <div className="container-fluid">
            <div className="row">
              <div className="btn-menu">
                <span></span>
              </div>
              <HeaderMenu />
              <SliderWrapper />
            </div>
          </div>
        </header>

        <section className="section-about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="tf-about center">
                  <h1 className="title-about wow fadeInUp">{t("Home.1")}</h1>

                  <p className="inner-title-about"> {t("Home.2")}</p>
                  <p className="text-about">{t("Home.3")}</p>
                  <div className="img-video">
                    <div className="video-responsive">
                      <ReactPlayer url="https://www.youtube.com/watch?v=T0LtF_naozM" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Services />
        <RWTCModel />
        <Banner />
        <Team />
        <SpecialProcess />
        <Testimonials />
      </div>
    </>
  );
}
export default Index;
