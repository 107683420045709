import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18next";

function RWTCModel() {
  const { t } = useTranslation();
  return (
    <section className="section-portfolio">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <p className="subtitle-portfolio">{t("HomeModel.1")}</p>
            <h3 className="title-portfolio">{t("HomeModel.2")}</h3>
          </div>
          <div className="col-md-7">
            <div className="button-portfolio wow fadeInUp">
              {/* <a className="gain-button button-color" href="portfolio.html"> */}
              <Link className="gain-button button-color" to="/aboutus">
                {t("HomeModel.3")}
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="img-box wow fadeInUp">
              <img
                src="./assets/images/offering/HomeSI.png"
                alt=""
                className="img-tf-1"
              />
              <div className="tf-wrap-content-portfolio tf-style-portfolio style-2">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/whatsnewsdetails/4">{t("HomeModel.4")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/whatsnewsdetails/4">{t("HomeModel.5")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/whatsnewsdetails/4"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-5">
            <div className="img-box active wow fadeInUp">
              <img
                src="./assets/images/offering/OfferingC.png"
                alt=""
                className="img-tf-2"
              />
              <div className="tf-wrap-content-portfolio">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/offering_list">{t("HomeModel.6")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/offering_list">{t("HomeModel.7")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/offering_list"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="img-box wow fadeInUp">
              <img
                src="./assets/images/offering/HomeSD2.JPG"
                alt=""
                className="img-tf-3"
              />
              <div className="tf-wrap-content-portfolio flat-style-portfolio style-2">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/whatsnewsdetails/5">{t("HomeModel.8")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/whatsnewsdetails/5">{t("HomeModel.9")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/whatsnewsdetails/5"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="img-box wow fadeInUp">
              <img
                src="./assets/images/offering/HomeIZ.jpg"
                alt=""
                className="img-tf-4"
              />
              <div className="tf-wrap-content-portfolio tf-style-portfolio">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/whatsnewsdetails/7">{t("HomeModel.10")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/whatsnewsdetails/7">{t("HomeModel.11")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/whatsnewsdetails/7"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-5">
            <div className="img-box wow fadeInUp">
              <img
                src="./assets/images/offering/Greenhouses.png"
                alt=""
                className="img-tf-5"
              />
              <div className="tf-wrap-content-portfolio">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/whatsnewsdetails/6">{t("HomeModel.12")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/whatsnewsdetails/6">{t("HomeModel.13")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/whatsnewsdetails/6"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="img-box wow fadeInUp">
              <img
                src="./assets/images/offering/certifications.png"
                alt=""
                className="img-tf-6"
              />
              <div className="tf-wrap-content-portfolio flat-style-portfolio style-2">
                <div className="tf-content-portfolio">
                  <ul className="tf-cat-portfolio">
                    <li>
                      <a href="/offering_list">{t("HomeModel.14")} </a>
                    </li>
                  </ul>
                  <div className="tf-title-portfolio title-32">
                    <a href="/offering_list">{t("HomeModel.15")}</a>
                  </div>
                  <a
                    className="tf-readmore-portfolio"
                    href="/offering_list"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RWTCModel;
