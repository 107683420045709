import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import fileDownload from "js-file-download";
import axios from "axios";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { Table } from "../utils/funciones";
import HeaderMenu from "../components/HeaderMenu";
import dataFincas from "../data/FincasActivas.json";

function Farmer() {
  const { t } = useTranslation();
  const columns = useMemo(() => [
    {
      Header: "Country",
      accessor: "Pais",
    },
    {
      Header: "STOCK (BAGS)",
      accessor: "STOCK_BAGS",
    },
    {
      Header: "PACKAGING",
      accessor: "PACKAGING_LBS",
    },
    {
      Header: "VARIETAL",
      accessor: "VARIETAL",
    },
    {
      Header: "PROCESS",
      accessor: "PROCESS",
    },
    {
      Header: "ORIGIN",
      accessor: "ORIGIN",
    },

    {
      Header: "CUP PROFILE",
      accessor: "CUP_PROFILE",
    },
    {
      Header: "POSITION",
      accessor: "POSITION",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [showModalItems, setShowModalItems] = useState(false);
  const [dataItems, setDataItems] = useState({ item: null, data: [] });
  const [dataItemsFilter, setDataItemsFilter] = useState({
    item: null,
    data: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [showDescarga, setShowDescarga] = useState(false);
  const [fincas, setFincas] = useState([]);
  const [infoFarmer, setInfoFarmer] = useState({
    id: 21,
    NombreFinca: "FINCA UNION I",
    Ficha: "./assets/FichasTecnicas/21_en.pdf",
    idEstatus: 5,
  });
  const [formData, setFormData] = useState({
    Name: "",
    Company: "",
    Business: null,
    eMail: "",
    terms: false,
    Other: "",
  });

  const [errorformData, setErrorFormData] = useState({
    Name: "",
    Company: "",
    Business: "",
    eMail: "",
  });

  // Add event listeners
  useEffect(() => {
    setLoading(true);
    const fincasData = _.shuffle(
      dataFincas.data.filter((item) => item.idEstatus === 5)
    );
    //console.log("=>fincas dspues: ", fincasData);

    const fincaMinimo = [];
    fincasData.forEach((item, index) => {
      if (index < 8) fincaMinimo.push(item);
    });
    console.log("=>fincas min: ", fincaMinimo);

    setFincas(fincaMinimo);

    window.addEventListener("keyup", keyupEvento);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keyup", keyupEvento);
    };
  }, []);

  useEffect(() => {
    if (fincas.length > 0) {
      setLoading(false);
    }
  }, [fincas]);

  // useEffect(() => {
  //   console.log("=>formData: ", formData);
  // }, [formData]);

  const keyupEvento = (e) => {
    if (e.keyCode === 27) setShowModal(false);
    //console.log("=>keyUp: ", e);
  };

  const mostrarModal = (item) => {
    setInfoFarmer(item);
    setShowModal(true);
  };

  const handleDownload = (url, filename) => {
    console.log("=>descarga");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        console.log("=>Empieza la descarga");
        fileDownload(res.data, filename);
      })
      .finally(() => {
        onCloseModal();
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("=>onSubmit", formData);
    setErrorFormData({
      Name: "",
      Company: "",
      Business: "",
      Other: "",
      eMail: "",
      terms: "",
    });

    if (formData.Name.length < 2) {
      setErrorFormData({
        ...errorformData,
        Name: "Name is required",
        Company: "",
        Business: "",
        Other: "",
        eMail: "",
        terms: "",
      });
      return true;
    }

    if (formData.Company.length < 4) {
      setErrorFormData({
        ...errorformData,
        Name: "",
        Company: "Company is required",
        Business: "",
        Other: "",
        eMail: "",
        terms: "",
      });
      return true;
    }
    if (formData.Business === null) {
      setErrorFormData({
        ...errorformData,
        Name: "Name is required",
        Company: "",
        Business: "Business is required",
        Other: "",
        eMail: "",
        terms: "",
      });
      return true;
    }

    if (formData.Business === 3) {
      if (formData.Other.length < 3) {
        setErrorFormData({
          ...errorformData,
          Name: "",
          Company: "",
          Other: "Other Business is required",
          eMail: "",
          terms: "",
        });
        return true;
      }
    }

    if (formData.eMail.length < 6) {
      setErrorFormData({
        ...errorformData,
        Name: "",
        Company: "",
        Business: "",
        Other: "",
        eMail: "eMail is required",
        terms: "",
      });
      return true;
    }

    if (formData.terms === false) {
      setErrorFormData({
        ...errorformData,
        Name: "",
        Company: "",
        Business: "",
        Other: "",
        eMail: "",
        terms: "Please Accept Terms and Conditions",
      });
      return true;
    }

    console.log("=>Inicio envio de correo", formData);

    // emailjs
    //   .sendForm(
    //     "service_pb53f4h",
    //     "template_ueluks9",
    //     formData,
    //     "user_iFoSKjKMJ5WHpJrT3YUK5"
    //   )
    //   .then(
    //     (result) => {
    //       console.log("=>correo enviado", result.text);
    //     },
    //     (error) => {
    //       console.log("=>Error envio de correo", error.text);
    //     }
    //   );

    var data = {
      service_id: "service_pb53f4h",
      template_id: "template_ueluks9",
      user_id: "user_iFoSKjKMJ5WHpJrT3YUK5",
      template_params: {
        Name: formData.Name,
        reply_to: "nugasoft@gmail.com",
        Company: formData.Company,
        email: formData.eMail,
        Finca: infoFarmer.NombreFinca,
        Business:
          formData.Business === 1
            ? "Roaster"
            : formData.Business === 2
            ? "Green coffee trader"
            : formData.Other,
        to_name: "Dietter",
      },
    };

    //"g-recaptcha-response": "03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...",

    var requestInfo = {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };

    console.log("=>Send email: ", data);

    fetch("https://api.emailjs.com/api/v1.0/email/send", requestInfo)
      .then((response) => {
        console.log("=>response: ", response);
        return response.json();
      })
      .then(() => {
        console.log("=>Correo enviado");
      })
      .catch((err) => {
        console.log("=>Error: ", err);
      })
      .finally(() => {
        console.log("=>Fin del envio");
        setShowDescarga(true);
      });
  };
  const onCloseModal = () => {
    setShowDescarga(false);
    setShowModal(false);
  };

  const onCloseModalItems = () => {
    setShowModalItems(false);
  };

  const mostrarModalItems = (item) => {
    // setInfoFarmer(item);
    // setShowModal(true);
    getProducts(item);
  };

  const getProducts = (item) => {
    console.log("=>getProducts: ");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filtered: [{ id: "shop_catalogos.ESTATE", value: item.NombreFinca }],
        page: 0,
        pageSize: 9999,
      }),
    };
    fetch("https://api.rwtc.mx/api/getShopCatalogo", requestOptions)
      .then((response) => response.json())
      .then((dataRS) => {
        console.log("=>getProducts dataRS:", dataRS);
        if (dataRS.success === true) {
          setShowModalItems(true);
          setDataItems({ item: item, data: dataRS.data });
          setDataItemsFilter({ item: item, data: dataRS.data });
        }
      })
      .catch((error) => {
        console.error("=>Error:", error);
      })
      .finally(() => {
        console.log("=>finally");
      });
  };

  const btnSelectProducto = (items) => {
    console.log("=>Items: ", items);
  };

  if (isLoading) return <div>Loading</div>;

  return (
    <div className="counter-scroll">
      <header id="headerFarmer">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
            <div className="wrap-banner">
              <div className="heading-title-page">
                {" "}
                {parse(t("HomeFarmer.1"))}{" "}
              </div>
              <span className="heading-title-content">
                <span className="style-5">{parse(t("HomeFarmer.2"))} </span>
              </span>
            </div>
          </div>
        </div>
      </header>
      <Modal
        open={showModal}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <h2>{infoFarmer.NombreFinca}</h2>
        <p>
          Hello, we want to meet you, please fill the following information to
          download the technical sheet
        </p>
        {!showDescarga && (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                id="Name"
                onChange={(data) =>
                  setFormData({ ...formData, Name: data.target.value })
                }
              />
              <span className="error_texto">{errorformData.Name}</span>
            </div>
            <div className="form-group">
              <label htmlFor="name">Company</label>
              <input
                className="form-control"
                id="Company"
                onChange={(data) =>
                  setFormData({ ...formData, Company: data.target.value })
                }
              />
              <span className="error_texto">{errorformData.Company}</span>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="radio"
                  checked={formData.Business === 1}
                  onChange={() => setFormData({ ...formData, Business: 1 })}
                />
                Roaster
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  checked={formData.Business === 2}
                  onChange={() => setFormData({ ...formData, Business: 2 })}
                />
                Green coffee trader
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label>
                <input
                  type="radio"
                  checked={formData.Business === 3}
                  onChange={() => setFormData({ ...formData, Business: 3 })}
                />
                Other
              </label>
              <span className="error_texto">{errorformData.Business}</span>
            </div>
            {formData.Business === 3 && (
              <div className="form-group">
                <label htmlFor="name">Other</label>
                <input
                  className="form-control"
                  id="Other"
                  onChange={(data) =>
                    setFormData({ ...formData, Other: data.target.value })
                  }
                />
                <span className="error_texto">{errorformData.Other}</span>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.com"
                onChange={(email) =>
                  setFormData({ ...formData, eMail: email.target.value })
                }
              />
              <span className="error_texto">{errorformData.eMail}</span>
            </div>
            <div className="form-group">
              <a
                target="_blank"
                href="https://shop.rwtc.mx/aviso_privacidad"
                rel="noopener noreferrer"
              >
                <label htmlFor="email">Accept Terms and Conditions</label>
              </a>
              <input
                type="checkbox"
                className="form-control"
                id="terms"
                checked={formData.terms}
                onChange={(terms) =>
                  setFormData({ ...formData, terms: !formData.terms })
                }
              />
              <span className="error_texto">{errorformData.terms}</span>
            </div>
            <div className="form-group">
              <button className="form-control btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        )}
        <br />
        <br />
        {showDescarga && (
          <button
            onClick={() => {
              handleDownload(infoFarmer.Ficha, `${infoFarmer.NombreFinca}.pdf`);
            }}
          >
            Download Data sheet
          </button>
        )}
        <br />
      </Modal>
      <Modal
        open={showModalItems}
        onClose={onCloseModalItems}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        {dataItemsFilter.item !== null && (
          <div>
            <h2>{dataItemsFilter.item.NombreFinca}</h2>
            <div style={{ textAlign: "center" }}>
              {parse(t("HomeFarmer.3"))} {dataItemsFilter.data.length}{" "}
              {parse(t("HomeFarmer.4"))} {dataItems.data.length}
              <Table
                columns={columns}
                data={dataItemsFilter.data}
                btnSelectProducto={btnSelectProducto}
              />
            </div>
          </div>
        )}

        <br />
      </Modal>
      <section className="section-team2">
        <div className="overlay-team"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 ">
              <div className="col-md-2 ">
                <div className="note"></div>
              </div>
              <div className="col-md-10 col-sm-12">
                <div className="heading-team2 wow fadeInUp">
                  <p className="subtitle-team2 subtitle-counter">
                    {parse(t("HomeFarmer.5"))}
                  </p>
                  <h3 style={{ color: "#000" }}>{parse(t("HomeFarmer.6"))}</h3>
                  <div className="button-team2">
                    <a className="gain-button" href="team.html">
                      {parse(t("HomeFarmer.7"))}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 ">
              {fincas.map((item) => {
                return (
                  <div key={item.id} className="col-md-3 col-sm-6">
                    <div className="team-box wow fadeInUp">
                      <div className="hover-team">
                        <img
                          src={`./assets/images/farmer/${item.id}.png`}
                          alt=""
                          className="img-team-box"
                        />
                      </div>
                      <div className="list-team-box">
                        <h3 className="title-team-box"> {item.NombreFinca} </h3>
                        <div>
                          <span
                            className="fa fa-file-pdf-o fa-1.5x"
                            aria-hidden="true"
                            onClick={() => mostrarModal(item)}
                          ></span>
                          &nbsp;&nbsp;
                          <span
                            className="fa fa-shopping-cart fa-1.5x"
                            aria-hidden="true"
                            onClick={() => {
                              mostrarModalItems(item);
                              return false;
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Farmer;
