import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  user: null,
  isLogin: false,
  Solicitudes: [],
  currentMenu: { id: 0, Menu: "Resumen" },
  posts: [],
  error: null,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};
