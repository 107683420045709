import React from "react";
import { useTranslation } from "react-i18next";
import "../i18next";

function SpecialProcess() {
  const { t } = useTranslation();
  return (
    <section className="section-history">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="tf-img-history">
              <img
                src="./assets/images/special_process/special_process_aa.png"
                alt=""
                className="img-1-history"
              />
              <img
                src="./assets/images/special_process/special_process_ri2.png"
                alt=""
                className="img-2-history"
              />
              <img
                src="./assets/images/special_process/special_process_fa2.jpg"
                alt=""
                className="img-3-history"
              />
            </div>
          </div>

          <div className="col-md-6 col-sm-6">
            <div className="heading-history tf-heading margin-top-162 wow fadeInUp">
              <h1 className="title-history">{t("HomeSpecialProcess.1")}</h1>
              <p className="text-history">{t("HomeSpecialProcess.2")}</p>
            </div>
            <ul className="history-box wow fadeInUp">
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.3")}</p>
              </li>
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.4")}</p>
              </li>
            </ul>
            <ul className="history-box wow fadeInUp">
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.5")}</p>
              </li>
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.6")}</p>
              </li>
            </ul>
            <ul className="history-box wow fadeInUp">
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.7")}</p>
              </li>
              <li className="icon-tf">
                <p className="title-icon">{t("HomeSpecialProcess.8")}</p>
              </li>
            </ul>

            <div className="button-history">
              <a
                className="gain-button button-color color2"
                href="/offering_list"
              >
                {t("HomeSpecialProcess.9")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SpecialProcess;
