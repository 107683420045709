async function save(key, value) {
  console.log(`=>Save Var: ${key}`, value);
  await localStorage.setItem(key, value);
  //await AsyncStorage.setItem(key, JSON.stringify(value));
  console.log(`=>Save ready!!: ${key}`);
}

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_POSTS":
      return {
        ...state,
        posts: action.payload,
      };

    case "SET_LOGIN":
      save("tl_usr", action.payload);
      return {
        ...state,
        user: action.payload,
        isLogin: true,
      };
    case "GO_LOGIN":
      //save("v_usr", action.payload);
      console.log("=>reducer GO_LOGIN:", action.payload);
      return {
        ...state,
        user: action.payload,
        isLogin: true,
      };
    case "SET_MENU":
      console.log("=>Update Menu: ", action.payload);
      return {
        ...state,
        currentMenu: action.payload,
      };
    case "SET_LOGOUT":
      console.log("Es logout");
      return {
        ...state,
        isLogin: false,
      };
    case "ADD_POST":
      return {
        ...state,
        posts: state.posts.concat(action.payload),
      };

    case "SolicitudesAdd":
      var newSolicitudes = state.Solicitudes.concat(action.payload);
      console.log("=>Solicitudes Save Reducer: ", newSolicitudes);
      //save("tl_sol", newSolicitudes);
      return {
        ...state,
        Solicitudes: state.Solicitudes.concat(action.payload),
      };

    case "REMOVE_POST":
      return {
        ...state,
        posts: state.posts.filter((post) => post.id !== action.payload),
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
