import React from "react";
import { useTranslation } from "react-i18next";
import "../i18next";

function SliderWrapper() {
  const { t } = useTranslation();
  return (
    <div className="rev_slider_wrapper">
      <div id="rev-slider1" className="rev_slider">
        <ul>
          <li>
            <div className="bg-slider-1"></div>

            <img
              src="./assets/images/slider/rwtc.png"
              className="tf-img-slider"
              alt=""
              data-bgposition="center center"
              data-no-retina
            />
            <div
              className="tp-caption tp-resizeme title-55"
              data-x="['left','left','left','center']"
              data-hoffset="['40','90','30','14']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','40','0','40']"
              data-fontsize="['72','62','50','42']"
              data-lineheight="['86','70','64','48']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="400"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="tittle1">{t("HomeSlider.1")}</span>
            </div>
            <div
              className="tp-caption button-slider"
              data-x="['left','left','left','center']"
              data-hoffset="['510','465','300','70']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','140','120','200']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="700"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="heading-title-content">
                <span className="style-5-banner">{t("HomeSlider.2")}</span>
              </span>
            </div>
          </li>
          <li>
            <div className="bg-slider-1"></div>

            <img
              src="./assets/images/slider/CARRUSEL1.png"
              className="tf-img-slider"
              alt=""
              data-bgposition="center center"
              data-no-retina
            />
            {/* </div> */}
            <div
              className="tp-caption tp-resizeme title-55"
              data-x="['left','left','left','center']"
              data-hoffset="['40','90','30','14']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','40','0','40']"
              data-fontsize="['72','62','50','42']"
              data-lineheight="['86','70','64','48']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="400"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="tittle1"> {t("HomeSlider.3")}</span>
            </div>
            <div
              className="tp-caption button-slider"
              data-x="['left','left','left','center']"
              data-hoffset="['510','465','300','70']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','140','120','200']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="700"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="heading-title-content">
                <span className="style-5-banner">{t("HomeSlider.4")}</span>
              </span>
            </div>
          </li>

          <li data-transition="random">
            <div className="bg-slider-1"></div>

            <img
              src="./assets/images/slider/FincaHuehuetepan.png"
              className="tf-img-slider"
              alt=""
              data-bgposition="center center"
              data-no-retina
            />
            <div
              className="tp-caption tp-resizeme title-55"
              data-x="['left','left','left','center']"
              data-hoffset="['40','90','30','14']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','40','0','40']"
              data-fontsize="['72','62','50','42']"
              data-lineheight="['86','70','64','48']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="400"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="tittle1">{t("HomeSlider.5")}</span>
            </div>
            <div
              className="tp-caption button-slider"
              data-x="['left','left','left','center']"
              data-hoffset="['510','465','300','70']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','140','120','200']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="700"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="heading-title-content">
                <span className="style-5-banner">{t("HomeSlider.6")}</span>
              </span>
            </div>
          </li>

          <li data-transition="random">
            <div className="bg-slider-1"></div>

            <img
              src="./assets/images/slider/FincaMonteVerde.png"
              className="tf-img-slider"
              alt=""
              data-bgposition="center center"
              data-no-retina
            />
            {/* </div> */}
            <div
              className="tp-caption tp-resizeme title-55"
              data-x="['left','left','left','center']"
              data-hoffset="['40','90','30','14']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','40','0','40']"
              data-fontsize="['72','62','50','42']"
              data-lineheight="['86','70','64','48']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="400"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="tittle1">{t("HomeSlider.7")}</span>
            </div>
            <div
              className="tp-caption button-slider"
              data-x="['left','left','left','center']"
              data-hoffset="['510','465','300','70']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','140','120','200']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="700"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="heading-title-content">
                <span className="style-5-banner">{t("HomeSlider.8")}</span>
              </span>
            </div>
          </li>
          <li data-transition="random">
            <div className="bg-slider-1"></div>

            <img
              src="./assets/images/slider/FincaCustepec.png"
              className="tf-img-slider"
              alt=""
              data-bgposition="center center"
              data-no-retina
            />

            <div
              className="tp-caption tp-resizeme title-55"
              data-x="['left','left','left','center']"
              data-hoffset="['40','90','30','14']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['0','40','0','40']"
              data-fontsize="['72','62','50','42']"
              data-lineheight="['86','70','64','48']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="400"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="tittle1">{t("HomeSlider.9")}</span>
            </div>
            <div
              className="tp-caption button-slider"
              data-x="['left','left','left','center']"
              data-hoffset="['510','465','300','70']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','140','120','200']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start="700"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              <span className="heading-title-content">
                <span className="style-5-banner">{t("HomeSlider.10")}</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SliderWrapper;
