import React from "react";

import "react-responsive-modal/styles.css";

import ChangeLanguaje from "../components/ChangeLanguaje";
import HeaderMenu from "../components/HeaderMenu";

function NoticePrivacy() {
  return (
    <div className="counter-scroll">
      <header id="headerPrivacy">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
            <div className="wrap-banner">
              <div className="heading-title-page"> Terms & Conditions </div>
              <span className="heading-title-content">
                <span className="style-5"> Check out about RWTC Policy </span>
              </span>
            </div>
          </div>
        </div>
      </header>

      <section className="tf-bottom-about ">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-2"></div>
            <div className="col-md-8 col-sm-8">
              <div className="tf-text-service-aviso wow fadeInUp">
                To the users (as defined below), we inform you that this privacy
                notice is applicable to you for the use or access to any of our
                pages, web and mobile applications, software, and applications
                in general that integrate the rwtc.mx platform (referred to as
                the “platform” hereinafter), so we understand that you agree and
                accept to be bound by its compliance.
                <b>
                  In the case that you do not agree with this privacy notice, or
                  the terms and conditions here mentioned, you must refrain from
                  accessing and using the platform.
                </b>
                <br />
                <br /> The user is understood as the person who accesses or uses
                the platform through computer equipment or any communication
                equipment or device (referred to as the “user” hereinafter). The
                user fully accepts each term and condition included in this
                privacy notice.
                <br />
                <br />
                <b>RESPONSIBLE FOR THE USE OF YOUR PERSONAL DATA</b> To
                OPERADORA AGLECO DE CHIAPAS SA DE CV (referred to as the
                “company” hereinafter), the safety of Users is our priority, so
                we protect your personal data through the use, application and
                maintenance of high technical, physical and administrative
                security measures. <br />
                <br />
                As a User, you can choose from a wide range of products and
                services though our platform, knowing that your personal data
                will be protected and treated confidentially. We inform you that
                the RESPONSIBLE for collecting, treating and using the personal
                data that the User provides through the Platform0 is the
                Company, as well as its subsidiaries, associates, holding
                companies and affiliates. <br />
                <br />
                <b>ADRESS OF THE RESPONSIBLE.</b> For purposes of this privacy
                notice, the Company indicates its address the one located in
                CALZADA EMILIANO ZAPATA 940 A, TERAN, Tuxtla Gutiérrez, Chiapas,
                29050. <br />
                <br />
                <b>PERSONAL DATA THAT MAY BE COLLECTED.</b> The personal data
                that the Company may collect from the User when using the
                Platform or hiring our services and products are the following:{" "}
                <br />
                <br />
                I. Name, phone, email and address <br />
                <br />
                Sensitive personal data will be considered as such, those
                provided by the Federal Law on Protection of Data Held by
                Private Parties, which are, but not restricted to, the data that
                is part of the privacy of the staff and whose disclosure puts at
                risk or gives rise to discrimination based on aspects of racial
                or ethnic origin, health status, genetic information, religious,
                philosophical or moral beliefs, union affiliation, political
                opinions and sexual preferences. <br />
                <br />
                <b>PURPOSES OF PROCESSING YOUR PERSONAL DATA.</b>
                <br />
                <br />
                The personal data that the Company collects will be used to meet
                the following purposes:
                <br /> I. General information and product prices. <br />
                <br />
                II. To integrate files, databases and systems necessary to carry
                out the corresponding operations and services; (ii) To claim the
                delivery of prizes and/or promotions; (iii) To carry out
                internal analysis. <br />
                <br />
                III. Additionally, your personal data may be used for the
                following secondary purposes: (i) Marketing, advertising and
                commercial prospecting; (ii) Offer you, where appropriate, other
                products and services of the Company or of any of its
                affiliates, subsidiaries, holding companies, associates,
                commission agents or companies; (iii) Send you promotions of
                other goods, services and/or products; (iv) To perform
                statistical analysis, generation of information models and/or
                current and predictive behavior profiles and, (v) To participate
                in surveys, raffles and promotions. <br />
                <br />
                <b>OPTIONS AND MEANS TO LIMIT THE USE OR DISCLOSURE OF DATA</b>
                The Company has implemented administrative, technical and
                physical security measures to protect your personal data, which
                we also require to be met by the service providers we hire. You
                may limit the use or disclosure of your personal data by sending
                an email to operaciones@rwtc.mx indicating your full name in the
                body of the email and what data you wish not to be disclosed. If
                your request proceeds, you will be registered on the list of
                exclusion. <br />
                <br />
                <b>
                  MEANS TO EXERCISE THE ACCESS, RECTIFICATION, CANCELLATION OR
                  OPPOSITION RIGHTS (ARCO RIGHTS).
                </b>
                ). You can send an email to operaciones@rwtc.mx, at any time, to
                exercise your Access, Rectification, Cancellation or Opposition
                rights ("ARCO RIGHTS"). To exercise the ARCO Rights, you (or
                your legal representative), must submit the request, identifying
                yourself with the following documentation: <br />
                <br />
                I. User’s name <br />
                <br />
                II. User’s address or any other means to communicate the
                response to your request. <br />
                <br />
                III. Documents that prove your identity (such as passport) and,
                if necessary, the document that prove the legal representation
                of the applicant. <br />
                <br />
                IV. A description of the information/data on which you are
                trying to exercise your ARCO rights and the rights you would
                like to exercise. <br />
                <br />
                If one or more of the documents listed above are missing, and/or
                the documents have errors or omissions, the Company will notify
                you within 3 business days of receipt of the request and ask you
                for the missing documents and/or relevant corrections; You will
                have 5 business days from that notification to provide the
                updated information, otherwise, the request will be understood
                as not submitted. <br />
                <br />
                <b>TRANSFER OF PERSONAL DATA.</b>
                The Company may disclose your personal data to those third
                parties who, by virtue of the services and products offered,
                need to know them in order to fully comply with them.
                <br />
                <br />
                Likewise, the Company may disclose your information to the
                competent authorities in terms of the applicable legislation;
                Any transfer of your personal data without consent will be
                carried out in accordance with Article 37 of the LFPDPP P.{" "}
                <br />
                <br />
                <b>WEB BEACONS.</b> . The Company may or may not use tracking
                technologies such as web beacons, like cookies, to collect data
                about your visits to the Portal; these are small electronic
                images embedded in web content or email messages, which are not
                normally visible to Users and that allow us to generate almost
                personalized content to offer you a better experience when you
                use our Platform. <br />
                <br />
                In case of not agreeing with any of the conditions established
                here, the User can always change the configuration of his
                browser. <br />
                <br />
                <b>MODIFICATIONS TO THE PRIVACY NOTICE. </b>This privacy notice
                may undergo modifications, changes or updates derived, among
                other things, by new legal requirements; own needs of the
                Company, for the products or services we offer; for our privacy
                practices; due to changes in our business model, or for other
                reasons. <br />
                <br />
                Any modification to this privacy notice will be notified through
                any of the following means: a written communication sent to your
                address; by the email you indicate; a message to your mobile
                phone; a message made known through the Portal or any electronic
                means used to carry out operations; or, in widely circulated
                newspapers in the Company's registered address.
              </div>
            </div>
            <div className="col-md-2 col-sm-2"></div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default NoticePrivacy;
