import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";
import "react-responsive-modal/styles.css";

import HeaderMenu from "../components/HeaderMenu";
import dataTeam from "../data/team.json";

function Team() {
  const { t } = useTranslation();
  return (
    <div className="counter-scroll">
      <header id="headerTeam">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </header>

      <section className="section-team">
        <div className="container">
          <div className="row">
            {dataTeam.data.map((item) => {
              return (
                <div className="col-md-3 col-sm-3">
                  <div className="team-box team-style wow fadeInUp">
                    <div className="hover-team">
                      <img
                        src={`./assets/images/team/${item.image}`}
                        alt=""
                        className="img-team-box"
                      />
                    </div>
                    <div className="list-team-box">
                      <a href="#" className="">
                        <h3 className="title-team-box"> {item.Name} </h3>
                      </a>
                      <span className="icon-team">
                        <a
                          href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a
                          href="https://web.facebook.com/roasterswarehousingtradingco"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fa fa-linkedin-square"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a
                          href="https://wa.me/message/DFGA4EJTLAK5E1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="button2-team center"></div>
        </div>
      </section>
    </div>
  );
}
export default Team;
