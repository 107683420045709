import React from "react";
import { useTranslation } from "react-i18next";
import "../i18next";

function Services() {
  const { t } = useTranslation();
  return (
    <section className="section-service">
      <div className="container">
        <div className="row">
          <div className="heading-service center">
            <h1 className="title-service wow fadeInUp">
              {t("HomeServices.1")}
            </h1>
            {/* <img
              src="./assets/images/home/03.png"
              alt=""
              className="img-service"
            /> */}
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box-service wow fadeInUp">
              <img
                src="./assets/images/fincas/FincaHuehuetepan.jpeg"
                alt=""
                className=""
              />
              <div className="inner-box-service">
                <p className="wrap-title bottom20">{t("HomeServices.2")}</p>
                <a href="/farmer" className="">
                  <h3>{t("HomeServices.3")}</h3>
                </a>
                <p className="subtext-service">{t("HomeServices.4")}</p>
                <a href="/farmer" className="icon-right">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box-service wow fadeInUp">
              <img
                src="./assets/images/fincas/FincaMontePerla.jpeg"
                alt=""
                className=""
              />
              <div className="inner-box-service">
                <p className="wrap-title bottom20">{t("HomeServices.5")}</p>
                <a href="/farmer" className="">
                  <h3>{t("HomeServices.6")}</h3>
                </a>
                <p className="subtext-service">{t("HomeServices.7")}</p>
                <a href="/farmer" className="icon-right">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box-service wow fadeInUp">
              <img
                src="./assets/images/fincas/FincasLasCascadas.jpeg"
                alt=""
                className=""
              />
              <div className="inner-box-service">
                <p className="wrap-title bottom20">{t("HomeServices.8")}</p>
                <a href="/farmer" className="">
                  {" "}
                  <h3>{t("HomeServices.9")}</h3>{" "}
                </a>
                <p className="subtext-service">{t("HomeServices.10")}</p>
                <a href="/farmer" className="icon-right">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box-service wow fadeInUp">
              <img
                src="./assets/images/fincas/FincaUnion.jpeg"
                alt=""
                className=""
              />
              <div className="inner-box-service">
                <p className="wrap-title bottom20">{t("HomeServices.11")}</p>
                <a href="/farmer" className="">
                  <h3>{t("HomeServices.12")}</h3>
                </a>
                <p className="subtext-service">{t("HomeServices.13")}</p>
                <a href="/farmer" className="icon-right">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="button-service center wow fadeInUp">
          <a className="gain-button" href="/farmer">
            {t("HomeServices.14")}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Services;
